import React, {useState} from 'react'
import './global.css'
import Sidebar from '../Components/global/Sidebar'
import Navbar from '../Components/global/Navbar'
import HeroSection from '../Components/index/Hero/HeroSection'
import InfoSection from '../Components/index/InfoSection'
import { homeObjOne, homeObjTwo, homeObjThree } from '../Components/index/InfoSection/Data'
import { ThemeProvider } from 'styled-components'
import Services from '../Components/index/Services'
import FooterSection from '../Components/global/Footer'
import { footerContent } from '../Components/global/Footer/Data'
import GoToTop from '../Components/GoToTop'
import SimpleSlider from '../Components/index/HeroSlider'
import FuglySection from '../Components/index/FuglySection'
import AboutSection from '../Components/index/AboutSection'

const Home = () => {
    const theme = {
        color: "#154c79",
        hover: "#F1F1F2"
      }

      const [isOpen, setIsOpen] = useState(false)

      const toggle = () => {
      setIsOpen(!isOpen)
      }


    return (
        <ThemeProvider theme={theme}>
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/>
            <HeroSection />
            {/*<SimpleSlider>
            <div>
            <HeroSection />
            </div>
            <div>
            <AboutSection />
            </div>
            <div>
            <FuglySection />
            </div>
            </SimpleSlider>
            <InfoSection {...homeObjOne}/>
            <InfoSection {...homeObjTwo}/>
            <InfoSection {...homeObjThree}/>
            <Services />
            <FooterSection {...footerContent} /> 
            <GoToTop /> */}
        </div>
        </ThemeProvider>
    )
}

export default Home
