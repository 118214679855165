import React, {useState} from 'react'
import Sidebar from '../Components/global/Sidebar'
import Navbar from '../Components/global/Navbar'
import { ThemeProvider } from 'styled-components'
import FooterSection from '../Components/global/Footer'
import { footerContent } from '../Components/global/Footer/Data'

const ContactUs = () => {
    const theme = {
        color: "#fff",
        hover: "#F1F1F2"
      }
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <ThemeProvider theme={theme}>
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <FooterSection {...footerContent} />
        </div>
        </ThemeProvider>
    )
}

export default ContactUs
