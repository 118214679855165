import hero from '../../../../images/hero.jpg'
import hero2 from '../../../../images/hero2.jpg'
import hero3 from '../../../../images/hero3.jpg'

import React from "react";


const images = [
    hero,
    hero2,
    hero3
  ];
  
  export default function Carousel() {
    const [value, setValue] = React.useState(0);
  
    React.useEffect(() => {
      const interval = setInterval(() => {
        setValue((v) => {
          return v === 2 ? 0 : v + 1;
        });
      }, 5000);
      return () => clearInterval(interval);
    }, []);
  
    return (
        <img src={images[value]} alt="img" style={{
             minwidth: "100%", 
             minheight: "100%", 
             objectfit: "cover", 
             transition: "opacity 0.5s linear", 
            }} />
    );
  }