import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowreelSection from "../ShowreelSection";
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { reelObjOne, reelObjTwo } from '../ShowreelSection/Data'
import Sidebar from '../../global/Sidebar'
import Navbar from '../../global/Navbar'
import HeroSection from '../Hero/HeroSection'

export default class SimpleSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      
      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
      
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () =>
      this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };

    return (
      <div style={{position: "relative"}}>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
              {this.props.children}
        </Slider>
           {/* <FontAwesomeIcon icon={solid("angle-left")} 
                style={{ 
                position: "absolute", 
                left: "5rem", 
                cursor: "pointer",
                height: "3rem",
                zindex: "3",
                bottom: "50vh",
                color: "#ccc",
            }} 
                className="button" onClick={this.previous}>
            Previous
            </FontAwesomeIcon>
            <FontAwesomeIcon icon={solid("angle-right")} 
                style={{ 
                position: "absolute", 
                right: "5rem", 
                cursor: "pointer",
                height: "3rem",
                zindex: "3",
                bottom: "50vh",
                color: "#ccc"
            }} 
                className="button" onClick={this.next}>
            Next
            </FontAwesomeIcon>*/}
      </div>
    );
  }
}