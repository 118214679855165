import React from 'react'
import { FooterContainer, FooterWrapper, FooterColumn, PageOwnerH1, P, Author } from './FooterElements'

const FooterSection = ({author}) => {

    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterColumn>
                    <PageOwnerH1>THE FORCE OSLO</PageOwnerH1>               
                    </FooterColumn>
                <FooterColumn>
                    <P>Majorstuen</P>    
                    <P>Oslo, Norway</P>    
                    <P>+47 123456789</P>    
                    <P><a href="">contact@theforce.no</a></P>    
                </FooterColumn>
            </FooterWrapper>
            <Author> <p> &copy; VO INTERACTIVE (2022) | ALL RIGHTS RESERVED </p></Author>
        </FooterContainer>
    )
}

export default FooterSection