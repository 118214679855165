import styled from 'styled-components/macro';

export const FuglyContainer = styled.div`
    background: #ff3399;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
`