import React from 'react';
import { SidebarContainer, Icon, CloseIcon, 
        SidebarWrapper, SidebarMenu, SidebarLink, SidebarRoute, 
        SideBtnWrap 
    } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
                <Icon onClick={toggle}>
                    <CloseIcon />
                </Icon>
                <SidebarWrapper>
                    <SidebarMenu>
                        <SidebarLink to="consultation" onClick={toggle}>
                            Get Started
                        </SidebarLink>
                        <SidebarLink to="communication" onClick={toggle}>
                            Process
                        </SidebarLink>
                        <SidebarLink to="product" onClick={toggle}>
                            Product
                        </SidebarLink>
                        <SidebarLink to="services" onClick={toggle}>
                            Services
                        </SidebarLink>
                    </SidebarMenu>
                    <SideBtnWrap>
                        <SidebarRoute to="/contactus" onClick={toggle}>
                            Contact Us
                        </SidebarRoute>
                    </SideBtnWrap>
                </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
