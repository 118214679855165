import React, {useState} from 'react'
import { HeroContainer, HeroBg, VideoBg, ImgBg, ImgLogo, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, ArrowDown, IconWrapper } from './HeroElements'
import video from '../../../../videos/video.mp4'
import {HeroBtn} from '../../../ButtonElements'
import hero from '../../../../images/hero.jpg'
import herologo from '../../../../images/tfls.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import './Hero.css'
import Carousel from '../HeroCarousel'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    const [arrowdown,setArrowDown] = useState(false)
    const changeDisplay= () => {
        if(window.scrollY >= 10) {
            setArrowDown(true);
        }   else {
            setArrowDown(false);
        }
    };
    window.addEventListener('scroll', changeDisplay);
    
    return (
        <HeroContainer>
            <HeroBg>
            {/*  <VideoBg autoPlay loop muted src={video} type='video/mp4' /> */}
                <Carousel/>
            </HeroBg>
            <HeroContent>
                <HeroH1></HeroH1>
                <ImgLogo src={herologo}></ImgLogo>
                <HeroP>Sales excellence - Marketing 2.0</HeroP>
                <HeroBtnWrapper>
                    <HeroBtn to="#" 
                    onClick={(e) => {
                        window.location.href = "mailto:maren@theforce.no";
                        e.preventDefault();
                    }}
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}
                    primary="true"
                    $dark="true"
                    onHoverLight="true"
                    $onHoverLightColor="true">
                        CONTACT US {/* {hover ? <ArrowForward /> : <ArrowRight />} */}
                    </HeroBtn>
                </HeroBtnWrapper>
            </HeroContent>
            <IconWrapper>
            <FontAwesomeIcon icon={solid('chevron-down')} className={arrowdown ? 'arrowdown active' : 'arrowdown'} />
            </IconWrapper>
        </HeroContainer>
    )
}

export default HeroSection
