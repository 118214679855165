import './App.css';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import ContactUs from './pages/contactus';

function App () {

  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contactus" component={ContactUs} exact />
        <Route path='/facebook' component={() => { 
     window.location.href = 'https://www.facebook.com/TheForceOslo/'; 
     return null;
}}/>
        <Route path='/instagram' component={() => { 
     window.location.href = 'https://www.instagram.com/the_force_oslo/'; 
     return null;
}}/>
      </Switch>
    </Router>
  );
}

export default App;
