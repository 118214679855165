import styled from 'styled-components/macro';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

export const Nav = styled.nav`
    height: 80px;
    width: 100vw;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: absolute;
    bottom: 2rem;
    z-index: 10;
    transition: 0.5s ease;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }   
`

export const NavBarContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled(LinkRouter)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-right: -24px;
    font-weight: bold;
    text-decoration: none;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 768px) {
        display: flex;
    }
`

export const NavItem = styled.li`
    height: 80px;
`

export const NavLinks = styled.a`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 2rem;
    height: 100%;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        transform: scale(1.05, 0.95);
    }

    &:active {
    }
`

export const SoMeBtns = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: flex;
    }

`

export const IconLinks = styled(LinkRouter)`
    transition: ease-in-out 0.1s;

    &:hover {
    transform: scale(1.1);
    }
`