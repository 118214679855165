import React, {useState} from 'react';
import {
    Nav, NavBarContainer, NavLogo, MobileIcon,
    NavMenu, NavItem, NavLinks, SoMeBtns, IconLinks
} from './NavbarElements';
import { FaBars } from 'react-icons/fa';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import Scrollspy from 'react-scrollspy'
import slider from '../../index/HeroSlider';
import GoToTop from '../../GoToTop';

const Navbar = ({toggle}) => {
    const [navbar,setNavbar] = useState(false)
    const changeBackground = () => {
        if(window.scrollY >= 10) {
            setNavbar(true);
        }   else {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll', changeBackground);
    return (
        <>
            <Nav className={navbar ? 'navbar active' : 'navbar'}>
                    <NavBarContainer>
                        {/*
                        <NavLogo to="/">
                            THE FORCE
                        </NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <Scrollspy
                            items={ ['about', 'fugly', 'product', 'webshop'] }
                            currentClassName="active" >
                        <NavMenu>
                            <NavItem>
                                <NavLinks id="about" to="about">About</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks id="fugly" >Beautifugly Art</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks id="contact" to="#">Contact</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks id="about" to="product">About</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks id="webshop" to="services">Contact</NavLinks>
                            </NavItem>
                        </NavMenu>
                        </Scrollspy>  */}
                        <SoMeBtns>
                            <IconLinks to="facebook"><FontAwesomeIcon icon={brands("facebook")} className="icon"/></IconLinks>
                            <IconLinks to ="instagram"><FontAwesomeIcon icon={brands("instagram")} className="icon"/></IconLinks>
                        </SoMeBtns>
                    </NavBarContainer>
            </Nav>
        </>
    )
}

export default Navbar
