import styled from 'styled-components/macro';
import { Link as LinkRouter } from 'react-router-dom';

export const Button = styled(LinkRouter)`
    border-radius: 0;
    background: ${({primary}) => (primary ? props => props.theme.color : '#111')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({onHoverLight}) => (onHoverLight ? props => props.theme.hover : props => props.theme.color)};
    color: ${({onHoverLightColor}) => (onHoverLightColor ? props => props.theme.color : '#111')};
    }
`

export const HeroBtn = styled(LinkRouter)`
border-radius: .1rem;
background: #22222220;
white-space: nowrap;
padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
color: #fff;
font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
border: none;
outline: 2px solid #fff;
cursor: pointer;
text-decoration: none;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2s ease-in-out;

&:hover {
transition: all 0.2s ease-in-out;
background: #fff;
color: ${({onHoverLightColor}) => (onHoverLightColor ? props => props.theme.color : '#111')};
outline: 2px solid #fff;
}
`