import styled from 'styled-components/macro';

export const FooterContainer = styled.footer`
    display: grid;
    justify-content: start;
    align-items: start;
    width: 100%;
    min-height: 40vh;
    background: #222;
    border-top: #eee;
`

export const FooterWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    grid-gap: 5rem;
    min-height: 35vh;
    height: 100%;

    @media screen and (max-width: 960px) {
        grid-auto-flow: row;
        min-height: 25vh;
    } 

    @media screen and (max-width: 576px) {
        min-height: 15vh;
        grid-gap: 0;
    } 
`

export const FooterColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    margin-bottom: 2rem;
`
export const PageOwnerH1 = styled.h1`
    color: #eee;

    @media screen and (max-width: 576px) {
        font-size: 26px;
    } 
`
export const P = styled.p`
    color: #eee;
    margin: .2rem 0;
`

export const Author = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    color: #ccc;
    background: ${(props => props.theme.color)};
    width: 100vw;
    height: 5vh;
    min-width: 100%;
    max-width: 100%;
    justify-self: center;
    align-self: end;

    @media screen and (max-width: 576px) {
        font-size: .6rem;
    } 
`