import styled from 'styled-components/macro';
import {MdKeyboardArrowRight} from 'react-icons/md'

export const HeroContainer = styled.div`
    background: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;

     :before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
            180deg,
            rgba(0,0,0,0.2) 30%,
            rgba(0,0,0,0.6) 100%
            ),
            linear-gradient(180deg, rgba(0,0,0,0.2) 30%, transparent 100%);
        z-index: 2;

    }
`

export const HeroBg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 0 8rem;
    overflow: hidden;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const ImgBg = styled.img`
    width: 100%;
    min-height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #111;
`

export const ImgLogo = styled.img`
    max-width: 35vw;
    margin: 50px 0 20px;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);

    @media screen and (max-width: 768px) {
        max-width: 50vw;
    }

    @media screen and (max-width: 480px) {
        max-width: 90vw;
    }
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 160px;
    text-align: center;
    text-shadow: 0 0 5px #111;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin: 12px 0 5px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
    text-shadow: 2px 2px 5px #222;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #22222260;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
`

export const ArrowForward = styled(MdKeyboardArrowRight)`
    margin-left: 1rem;
    font-size: 20px;
    position: relative;
    top: .1rem;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 0;
    font-size: 20px;
    position: relative;
    top: .1rem;
`

export const IconWrapper = styled.span`
    color: ${props => props.theme.color};
`
